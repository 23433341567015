import React, { useEffect, useRef, useState } from "react";
import { Label } from "../../components/UI";
import styled from "styled-components";
import { Input, Icon, Theme } from "@dspworkplace/ui";
import CKeditorComponent from "../CkEditor/CKeditorComponent";

const HiddenInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;
const SpanTag = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-family: Circe-Rounded;
    display: block;
    color: rgb(81, 111, 144);
    margin-top: 4px;
`;
const Description = styled.div`
    border: 1px gray dashed;
    padding-left: 10px;
    padding-bottom: 5px;
`;

// Tiny Mce
const TinyMce = React.forwardRef(({ name, placeholder, ...props }, ref) => {
    // const tinyMceRef = useRef();
    const [valueInput, setValueInput] = useState(props.defaultValue);
    const [showDescription, setShowDescription] = useState(false);
    // const handleEditorChange = (value, editor) => {
    //     setValueInput(value);
    // };
    useEffect(() => {
        setValueInput(props.defaultValue);
    }, [props.defaultValue]);
    return (
        <div style={{ display: "inline-block" }}>
            <Label>{props.label}</Label>
            <CKeditorComponent
                content={valueInput}
                onChange={(evt) => {
                    var content = evt.editor.getData();
                    setValueInput(content);
                }}
            />
            <div>
                <div
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={() => {
                        setShowDescription(!showDescription);
                    }}
                >
                    <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                        <Icon.Info
                            size="16px"
                            color={Theme.colors.neutrals.medium}
                            style={{
                                verticalAlign: "middle",
                                position: "relative",
                                top: -1,
                            }}
                        />{" "}
                        Template Variable
                    </SpanTag>
                </div>
            </div>
            <div>
                {showDescription && (
                    <Description>
                        <SpanTag>
                            <span>[DRIVER_NAME] = "Driver name of Incident"</span>
                        </SpanTag>
                        <SpanTag>
                            <span>[DATE] = "Date of Incident"</span>
                        </SpanTag>
                        <SpanTag>
                            <span>[STATION] = "Station of Incident"</span>
                        </SpanTag>
                    </Description>
                )}
            </div>
            <HiddenInput type="text" name={name} id={props.token} ref={ref} value={valueInput} />
        </div>
    );
});
export default TinyMce;
