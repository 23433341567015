import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Spacer, Title, Button } from "@dspworkplace/ui";
import WeekPicker from "../../../components/Calendar/week";
import WeekTable from "./week";
import TabPanel from "./tabPanel";
import { WeekProvider } from "./context/weekStore";
import moment from "moment";
import ViewSelector from "../Common/ViewSelector";
import PublishPopUp from "../Common/PublishPopUp";
import { FilterWeekDate } from "../../../hooks/useGlobalData";
import PrintButton from "../Common/PrintButton";
import { useSelectedStation } from "../../../components/StationSelector";
import { showDriverSelection } from "../../SchedulerLoadOut/driverSelection";
import { getCompanyId, useAuth } from "../../../Auth";
import { confirm } from "../../../components/Confirm";
import WeekStore from "./context/weekStore";
import ExportButton from "../Common/ExportButton";
import CopyPreviousWeekShifts from "./components/CopyPreviousWeekShifts";
import { alert } from "../../../components/Alert";

const Body = styled.div`
    box-sizing: border-box;
    // max-height: 100vh;
    overflow: hidden;
    padding: 20px 20px 0 20px;

    @media (max-width: 1201px) {
        .title {
            font-size: 32px;
        }
    }
`;

const ActionBox = styled.div`
    display: flex;
    align-items: center;
`;

const ExportActions = styled.div`
    margin-left: auto;
    // display:grid;
    // grid-template-columns: 140px 85px 44px 44px;
    // grid-column-gap:12px;

    .test {
        display: flex;
    }
`;

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
`;

const App = () => {
    let [newDate, setNewDate] = FilterWeekDate();
    let [publishShift, setPublishShift] = useState("false");
    const [selectedStation] = useSelectedStation();
    const fakeRefresh = useState(0);
    const { api } = useAuth();
    let week = moment(newDate).isoWeek();
    if (moment(newDate).day() === 0) {
        week = moment(newDate).add(1, "days").isoWeek();
    }
    let year = parseInt(moment(newDate).startOf("week").format("YYYY"));
    if(moment(newDate).startOf("week").format('YYYY') != moment(newDate).endOf("week").format('YYYY') && week == 1 && year == moment(newDate).startOf("week").format('YYYY')) {
        year = parseInt(moment(newDate).endOf("week").format('YYYY'));
    }
    let dayArr = [];
    for (var i = 0; i < 7; i++) {
        dayArr[i] = moment(newDate).startOf("week").add(i, "days");
    }

    const dateObj = moment(newDate).toDate();
    const currentWeek = moment().isoWeek();
    const currentYear = moment().isoWeekYear();
    const recurringWeeks =  localStorage.getItem('recurringWeeks');
    const recurringLastWeekEndDate = moment().add(15, 'weeks').endOf('isoWeek').format("YYYY-MM-DD");
    const companyId = getCompanyId();

    return (
        <WeekProvider>
            <Body>
                <ActionBox>
                    <PublishPopUp date="" week={week} year={year} view={"week"} fakeRefresh={fakeRefresh} />
                    <Spacer inline left={5}>
                        <Title className={"title"}>
                            {moment(dayArr[0]).format("MMM DD")} – {moment(dayArr[6]).format("MMM DD")},{" "}
                            {moment(dayArr[6]).format("YYYY")}
                        </Title>
                    </Spacer>
                    <ExportActions>
                        <WeekPicker date={newDate} onChange={(date) => setNewDate(moment(date).format("YYYY-MM-DD"))} />
                        <Spacer inline right={3} />
                        {((week >= currentWeek && year >= currentYear) || (week < currentWeek && year > currentYear)) &&
                            (
                                <CopyPreviousWeekShifts
                                    week={week}
                                    year={year}
                                    isCurrentWeek={week == currentWeek && year == currentYear}
                                    stationId={selectedStation}
                                />
                            )}
                        <Spacer inline right={3} />
                        <PrintWeekWiew week={week} selectedStation={selectedStation} year={year}></PrintWeekWiew>
                        <Spacer inline right={3} />
                        <ExportWeekWiew week={week} year={year}></ExportWeekWiew>
                        <Spacer right={3} inline />
                        <ViewSelector />
                    </ExportActions>
                </ActionBox>
                <Spacer top={5} />
                <Container>
                    <TabPanel>
                        <Spacer top={5}>
                            <AddDriverButton fakeRefresh={fakeRefresh} />
                        </Spacer>
                    </TabPanel>

                    <WeekTable
                        year={year}
                        weekDate={dateObj}
                        week={week}
                        fakeRefresh={fakeRefresh}
                        setPublishShift={setPublishShift}
                    />
                </Container>
            </Body>
        </WeekProvider>
    );
};

const sortData = (array, fields, direction = 0) => {
    return array.sort((a, b) => {
        const strA = fields.reduce((str, key) => `${str}~${a[key]}`, "");
        const strB = fields.reduce((str, key) => `${str}~${b[key]}`, "");
        if (direction === 0) {
            return strA.localeCompare(strB);
        } else {
            return strB.localeCompare(strA);
        }
    });
};

const AddDriverButton = ({ fakeRefresh }) => {
    const [updateScheduler, setUpdatedScheduler] = fakeRefresh;
    const [state, dispatch] = useContext(WeekStore);
    let [newDate, setNewDate] = FilterWeekDate();
    const [selectedStation] = useSelectedStation();
    const { api } = useAuth();
    const handleAddDriver = async () => {
        showDriverSelection({}, api, {
            type: "SCHEDULER_ADD_ROUTE_WITH_DATE",
            station: selectedStation,
            date: newDate,
        }).then(async (data) => {
            if (!data) return;
            
            data.driver = data.driver;
            const params1 = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "checkSequenceDriverRoute",
                                get: "checkSequenceDriverRoute",
                                excludes: [],
                                criteria: {
                                    routeId: "",
                                    driverId: data.driver,
                                    newDate: data.date,
                                },
                            },
                        },
                    },
                },
            };
            const response = await api.post("/api/lazy/manage/data", params1);
            if (response.data.data.checkSequenceDriverRoute.status) {
                const wc = await confirm(response.data.data.checkSequenceDriverRoute.message.web);
                if (!wc) return undefined;

                const params1 = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "createEventForConsicutiveShift",
                                    get: "createEventForConsicutiveShift",
                                    excludes: [],
                                    criteria: {
                                        driverId: data.driver,
                                        message: response.data.data.checkSequenceDriverRoute.message.bot,
                                    },
                                },
                            },
                        },
                    },
                };
                const response2 = await api.post("/api/lazy/manage/data", params1);
            }
            const criteria = {
                shift: data.shift,
                driverId: data.driver,
                invoiceType: data.invoiceType,
                routeStatus: data.routeStatus,
                startTime: data.startTime,
                endTime: data.endTime,
                timezoneOffset: new Date().getTimezoneOffset(),
                date: data.date,
                station: selectedStation,
                inactiveCurrent: data.inactiveCurrent,
                backupStatus: data.backupStatus,
                onCall: data.onCall
            };
            const params = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "schedulerLoadOutAddDriverSave",
                                get: "result",
                                excludes: [
                                    "skill",
                                    "vehicle",
                                    "device",
                                    "driver",
                                    "station",
                                    "shiftType",
                                    "schedule",
                                    "vehicleDriverRecord",
                                    "status",
                                    "tempDriverRoutes",
                                    "kickoffLog",
                                    "returnToStation",
                                    "oldDriver",
                                    "oldDriverRoute",
                                    "driverRoutes",
                                    "oldTempDriverRoutes",
                                ],
                                criteria,
                            },
                        },
                    },
                },
            };
            try {
                const response = await api.post("/api/lazy/manage/data", params);
                if (data.station == selectedStation) {
                    const weekDate = moment(data.date).utc().format("YYYY-MM-DD");
                    if (data.inactiveCurrent == true) {
                        setUpdatedScheduler(updateScheduler + 1);
                    } else {
                        dispatch({
                            type: "ADD_DRIVER_WITH_SHIFT",
                            payload: {
                                to: {
                                    driverId: response.data.data.result.driverId,
                                    week: moment(weekDate).weekday(),
                                },
                                item: response.data.data.result,
                                hasAlert: true,
                            },
                        });
                        dispatch({
                            type: "ORDER",
                            payload: {
                                mainFields: [
                                    "firstName",
                                    "workHours"
                                ]
                            },
                        });
                    }
                } else {
                    setUpdatedScheduler(updateScheduler + 1);
                }
                return true;
            } catch (error) {
                if (error.response.status == "401") {
                    window.location = "/logout";
                }
            }
        });
    };

    return (
        <Button
            size="small"
            onClick={() => {
                handleAddDriver();
            }}
            type={"default"}
        >
            Add Driver
        </Button>
    );
};

const ExportWeekWiew = (props) => {
    const [state, dispatch] = useContext(WeekStore);
    return (
        <ExportButton
            params={{
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "getDriverShiftForWeekViewForExport",
                                get: "file",
                                criteria: {
                                    data: {
                                        listData: state.state, footerData: state.schedulerFooterData
                                    },
                                    weekNumber: props.week,
                                    yearNumber: props.year,
                                },
                            },
                        },
                    },
                },
            }}
        />
    );
};

const PrintWeekWiew = ({week,year,selectedStation, ...props}) => {
    const [state, dispatch] = useContext(WeekStore);
    return (
        <PrintButton
            params={{
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "createWeekViewPDF",
                                get: "file",
                                criteria: {
                                    station: selectedStation,
                                    wknumber: week,
                                    year: year,
                                    data: {
                                        schedulerRouteData: state.state, schedulerFooterDataArray: state.schedulerFooterData
                                    },
                                },
                            },
                        },
                    },
                },
            }}
        />
    );
};
export default App;
